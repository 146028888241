import { ConfigMurucaAdvancedSearchLayout } from '@net7/boilerplate-muruca';

export default {
  title: 'Ricerca avanzata',
  resultsUrl: { it: '/risultati' },
  hasDynamicOptions: true,
  formConfig: {
    submitButton: {
      label: 'advancedsearch#submit',
    },
    resetButton: {
      label: 'advancedsearch#reset',
    },
    groups: [
      {
        id: 'group-global-intro',
        sections: ['section-intro'],
        classes: 'form-group-1',
        options: {
          label: 'Prova',
          isOpen: true,
          showHeader: false,
        },
      },
      {
        id: 'group-global-search',
        sections: ['section-global'],
        classes: 'form-group-1',
        options: {
          label: 'advancedsearch#section_global',
          isOpen: true,
          showHeader: false,
        },
      },
      {
        id: 'group-2',
        sections: ['section-2', 'section-bibl'],
        classes: 'form-group-2',
        options: {
          label: 'advancedsearch#section_textual',
          isOpen: false,
          showHeader: true,
        },
      },
    ],
    sections: [
      {
        id: 'section-intro',
        title: '',
        description: 'advancedsearch#intro',
        advancedSection: true,
        inputs: [],
      },
      {
        id: 'section-global',
        title: 'advancedsearch#search_portal',
        inputs: [
          {
            id: 'query-fulltext',
            type: 'text',
            data: {
              id: 'query-fulltext',
              label: 'advancedsearch#fulltext_text',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'resource-type',
            type: 'checkbox',
            label: 'advancedsearch#label_title',
            info: 'advancedsearch#section_info',
            state: {
              value: [
                'testimoni',
                'biblioteca',
                'cronologia',
                'vita antica',
                'percorsi',
                'strumenti',
              ],
            },
            data: {
              id: 'resource-type',
              legend: 'advancedsearch#label_type',

              checkboxes: [
                {
                  label: 'advancedsearch#label_witnesses',
                  payload: 'testimoni',
                },
                {
                  label: 'advancedsearch#label_library',
                  payload: 'biblioteca',
                },
                {
                  label: 'advancedsearch#label_timeline',
                  payload: 'cronologia',
                },
                {
                  label: 'advancedsearch#label_biographies',
                  payload: 'vita antica',
                },
                {
                  label: 'advancedsearch#label_itineraries',
                  payload: 'percorsi',
                },
                {
                  label: 'advancedsearch#label_tools',
                  payload: 'strumenti',
                },
                {
                  label: 'advancedsearch#label_bibliography',
                  payload: 'bibliografia',
                },
              ],
            },
          },
        ],
      },
      {
        id: 'section-2',
        title: '',
        inputs: [
          {
            id: 'mrc_post_type',
            type: 'checkbox',
            label: 'advancedsearch#label_type_text',
            state: {
              value: ['work'],
            },
            data: {
              id: 'mrc_post_type',
              legend: 'advancedsearch#label_type_text',

              checkboxes: [
                {
                  label: 'advancedsearch#label_work',
                  payload: 'work',
                },
                {
                  label: 'advancedsearch#label_biographies',
                  payload: 'biography',
                },
              ],
            },
          },
          {
            title: 'advancedsearch#label_work_title',
            info: 'advancedsearch#label_work_title_info',
            id: 'mrc_work_post_title',
            type: 'select',
            classes: 'n7-select__medium_size',
            data: {
              id: 'mrc_work_post_title',
              label: 'advancedsearch#label_work_title',
              options: [],
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-text',
            type: 'text',
            info: 'advancedsearch#text_info',
            data: {
              label: 'advancedsearch#label_text',
              id: 'query-text',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-distance-value',
            type: 'select',
            data: {
              id: 'query-distance-value',
              label: 'advancedsearch#distance_value',
              payload: '',
              options: ['', 0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => ({
                value: number.toString(),
                label: number.toString(),
                disabled: false,
              })),
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            id: 'query-text-authority',
            type: 'checkbox',
            label: 'advancedsearch#querytext_authority_title',
            info: 'advancedsearch#querytext_authority_info',
            state: {
              value: ['1'],
            },
            data: {
              id: 'query-text-authority',
              legend: 'advancedsearch#querytext_authority_label',
              checkboxes: [
                {
                  label: 'advancedsearch#include_authority',
                  payload: '1',
                  checked: true,
                },
              ],
            },
          },
          {
            info: 'advancedsearch#label_name_info',
            id: 'mrc_work_mrc_tei_name',
            type: 'select',
            classes: 'n7-select__medium_size',
            data: {
              id: 'mrc_work_mrc_tei_name',
              label: 'advancedsearch#label_name',
              options: [],
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            info: 'advancedsearch#label_place_info',
            id: 'mrc_work_mrc_tei_place',
            type: 'select',
            classes: 'n7-select__medium_size',
            data: {
              id: 'mrc_work_mrc_tei_place',
              label: 'advancedsearch#label_place',
              options: [],
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
        ],
      },
      {
        id: 'section-bibl',
        advancedSection: false,
        inputs: [
          {
            id: 'query-bibl',
            type: 'text',
            data: {
              label: 'advancedsearch#label_text_bibl',
              id: 'query-bibl',
              placeholder: '',
              icon: 'n7-icon-search',
              inputPayload: 'search-input',
              enterPayload: 'search-enter',
              iconPayload: 'search-icon',
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
          {
            info: 'advancedsearch#label_bibl_info',
            id: 'mrc_work_mrc_tei_bibliography',
            type: 'select',
            classes: 'n7-select__medium_size',
            data: {
              id: 'mrc_work_mrc_tei_bibliography',
              label: 'advancedsearch#label_bibl',
              options: [],
            },
            state: {
              value: '',
              disabled: false,
              hidden: false,
            },
          },
        ],
      },
    ],
  },
} as ConfigMurucaAdvancedSearchLayout;
