import { ConfigMurucaResourceLayout } from '@net7/boilerplate-muruca';

export default {
  maxHeight: 100, // Threshold where the "read-more" button appears
  title: 'Oggetto',
  type: 'oggetto',
  sections: {
    top: [
      {
        id: 'header',
        type: 'title',
        grid: null,
      },
    ],
    content: [
      {
        id: 'metadata',
        type: 'metadata',
        title: 'resource#metadata',
      },
      // {
      //   id: 'collection-carte',
      //   type: 'collection',
      //   grid: 3,
      //   title: 'Carte collegate',
      // },
      // {
      //   id: 'collection-related',
      //   type: 'collection',
      //   grid: 1,
      //   title: 'Manoscritti collegati',
      // },
      // {
      //   id: 'text-viewer',
      //   type: 'text-viewer',
      // },
      // {
      //   id: 'collection-taxonomies',
      //   type: 'collection',
      //   grid: 3,
      //   title: 'Tassonomie collegate',
      // },
    ],
  },
} as ConfigMurucaResourceLayout;
