import { ConfigMurucaResourceLayout } from '@net7/boilerplate-muruca';

export default {
  maxHeight: 100, // Threshold where the "read-more" button appears
  title: 'Carta',
  type: 'carta',
  sections: {
    top: [
      {
        id: 'header',
        type: 'title',
        grid: null,
      },
    ],
    content: [
      {
        id: 'image-viewer-iiif',
        type: 'viewer-iiif',
        title: 'resource#image-viewer-iiif',
        options: {
          libOptions: {
            window: {
              sideBarOpenByDefault: false,
              allowClose: false,
              allowMaximize: true,
              defaultSideBarPanel: 'info',
              views: [
                { key: 'single' },
                { key: 'gallery' },
              ],
              workspaceControlPanel: {
                enabled: false,
              },
            },
            id: 'mirador-container',
          },
        }
      },
      {
        id: 'metadata',
        type: 'metadata',
        title: 'resource#metadata',
      },
      // {
      //   id: 'collection-related-serie',
      //   type: 'collection',
      //   grid: 3,
      //   title: 'Serie collegate',
      // },
      {
        id: 'collection-related',
        type: 'collection',
        grid: 1,
        title: 'Manoscritti collegati',
      },
      // {
      //   id: 'text-viewer',
      //   type: 'text-viewer',
      // },
      // {
      //   id: 'collection-taxonomies',
      //   type: 'collection',
      //   grid: 3,
      //   title: 'Tassonomie collegate',
      // },
    ],
  },
} as ConfigMurucaResourceLayout;
